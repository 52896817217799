<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="isTime"
    :return-value.sync="value"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        :error="dateError"
        :error-messages="errorMsg"
        :success="!dateError"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <template v-if="isTime">
      <v-time-picker
        v-if="menu"
        v-model="value"
        full-width
        @click:minute="$refs.menu.save(value)"
      ></v-time-picker>
    </template>
    <template v-else>
      <v-date-picker
        v-model="value"
        no-title
        scrollable
        locale="fr"
        firstDayOfWeek="1"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(value)">
          OK
        </v-btn>
      </v-date-picker>
    </template>
  </v-menu>
</template>
<script>
export default {
  model: {
    prop: "modelValue",
    event: "input"
  },
  props: {
    modelValue: {
      type: [Date, String],
      required: true
    },
    isTime: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    dateError: {
      type: Boolean
    },
    errorMsg: {
      type: String
    }
  },
  data() {
    return {
      menu: false
    };
  },
  computed: {
    value: {
      get: function() {
        return this.modelValue;
      },
      set: function(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
